import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Link from '../components/Link';
import { _404Icon } from '../components/Icons';

import locales from '../locales.json';

const _404 = props => {

    const lang = props.pageContext.langKey;

    return (
        <Layout lang={lang} location={props.location} showContact={false} showFooter={false}>
            <Seo title='404' />
            <div className="container">
                <div className="_404">
                    <div className="_404__content">
                        <h1 className="_404__heading">
                            404
                        </h1>
                        <p>
                            The page you are looking for may have <br />
                            been removed, had its name changed, or <br /> 
                            is temporarily unavailable. 
                        </p>
                        <Link lang={lang} to='/' className="btn btn__primary">
                            {locales._404.link.en}
                        </Link>
                    </div>
                    <div className="_404__image">
                        <_404Icon />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default _404;